import { Comment, Icon, Tooltip, Avatar } from "antd";
import moment from "moment";
import { BaseComponent } from "themes/components";

class CommentComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      notice: "",
    };
  }
  render() {
    const { item = [], avatar="" } = this.props;
    return (
      <Comment
        className="comment-component"
        avatar={
          <Avatar
            src={avatar}
            alt={item[1] || ""}
            shape="square"
          />
        }
        content={
          <div className="comment-content">
            <p>
              <img src="/static/assets/quote.svg" style={{width:"24px"}}/>
            </p>
            <p>
              {item[0] || ""} {/* nội dung */}
            </p>
            <h1>{item[1] || ""}</h1> {/* tên người */}
            <div>{item[2] || ""}</div> {/* vai trò */}
          </div>
        }
      />
    );
  }
}
export default CommentComponent;
