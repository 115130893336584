import { BaseComponent } from "themes/components";
import { Icon, Row, Col, Button } from "antd";
import Form from "themes/components/Form/Form";
import { Yup, Input, Textarea } from "themes/components/Form";
class ContactBlock extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      notice: "",
      isLoading: false,
    };
  }
  onSubmit = async (values) => {
    if (this.props.onSubmit) {
      this.setState({ isLoading: true });
      await this.props.onSubmit(values);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { className = "", publicSetting = {} } = this.props;
    let { address = "", phones = "", emails = "" } = publicSetting;
    return (
      <Row gutter={[24,24]} type="flex" justify="center">
        <Col xs={24} sm={24} md={12}>
          <Form
            onSubmit={this.onSubmit}
            className="login-form"
            validationSchema={Yup.object({
              name: Yup.string().required("Bạn chưa nhập trường này"),
              email: Yup.string().required("Bạn chưa nhập trường này"),
              content: Yup.string().required("Bạn chưa nhập trường này"),
            })}
            initialValues={{
              name: "",
              email: "",
              content: "",
            }}
          >
            <Input type="text" name="name" placeholder="Họ Tên" required />
            <Input
              type="email"
              name="email"
              placeholder="Địa chỉ Email"
              required
            />
            <Textarea name="content" placeholder="Lời nhắn" />
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={this.state.loadingBtn}
            >
              PHẢN HỒI
            </Button>
          </Form>
        </Col>
        <Col xs={24} sm={24} md={9} className="bg-contact padding-30">
          <h3><a>Liên hệ ngay với chúng tôi để được phục vụ chu đáo nhất!</a></h3>
          <br/>
          <Row type="flex">
            <Col xs={2}>
              <a>
                <Icon type="home" theme="filled"/>
              </a>
            </Col>
            <Col xs={22}>
              {address.split("+").map((item, index) => (
                <p key={index}>
                  CS{index + 1}: {item}
                </p>
              ))}
            </Col>
          </Row>
          <Row type="flex">
            <Col xs={2}>
              <a>
                <Icon type="mail" theme="filled"/>
              </a>
            </Col>
            <Col xs={22}>
              {emails.split("+").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </Col>
          </Row>
          <Row type="flex">
            <Col xs={2}>
              <a>
                <Icon type="phone" theme="filled" />
              </a>
            </Col>
            <Col xs={22}>
              {phones.split("+").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default ContactBlock;
