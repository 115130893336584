import { BaseComponent } from "themes/components";
import { Carousel } from "react-responsive-carousel";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";

class Banner extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      notice: "",
    };
  }

  redirectLink = (index) => {
    const { srcs = [] } = this.props;
    let { link } = srcs[index] || {};
    if (!link) return false;
    let win = window.open(link, "_blank");
    win.focus();
  };

  render() {
    const { srcs = [], className, content } = this.props;
    return (
      <div className={className}>
        {content && (
          <div className="content-wrapper">
            <div className="content">{content}</div>
          </div>
        )}
        <Carousel
          onClickItem={this.redirectLink}
          className="vertical-center-banner"
          autoPlay
          showThumbs={false}
          infiniteLoop={true}
          showStatus={false}
        >
          {srcs.map((img = {}, index) => (
            <img
              src={`/thumb?src=${img.url}&w=1500`}
              srcSet={`/thumb?src=${img.url}&w=1500 1x,
            /thumb?src=${img.url}&w=1000 4x`}
              key={index}
            />
          ))}
        </Carousel>
      </div>
    );
  }
}

export default Banner;
