import { BaseComponent } from "themes/components";

class TitleGroup extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      notice: "",
    };
  }

  render() {
    const { title="", description="" } = this.props;
    return (
      <div className="titleGroup">
        <h1 className="title2">{title}</h1>
      </div>
    );
  }
}

export default TitleGroup;
